import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, TimeFormat,DateThaiFormat } from '../../Utils/Function';
import {SearchSubDistrict,SearchCustomer} from '../../Utils/Search';
import {QuotationType,QuotationTypeName} from '../../Utils/Status';
import {CustomerInfoDetail} from '../../Components/Customer';
import { ProductView  } from '../../Components';
import parse from 'html-react-parser'
import moment from 'moment';
import AsyncSelect from 'react-select/async';

import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { ThaiDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import Popout from 'react-popout'
import NewWindow from 'react-new-window'
import PromotionItem from '../../Components/Promotion/PromotionItem';
const QuotationCreate = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const [_StartTime,setStartTime] = useState(null);
    const [_EndTime,setEndTime] = useState(null);
    const [_StandbyTime,setStandbyTime] = useState(null);

    const [ModalState,setModalState] = useState(false);
    const[ModalItemState,setModalItemState] = useState(false);
    
    const [EventDate,setEventDate] = useState(moment().add(3, 'day').toDate());
    const [AccountType,setAccountType] = useState(1);
    const [PackageGroupList,setPackageGroupList] = useState([]);
    const [_PackageList,setPackageList] = useState([]);
    const [_ProductTypeList,setProductTypeList] = useState([]);
    const [_ProductList,setProductList] = useState([]);
    const [_ItemRefID,setItemRefID] = useState(0);
    const [_ItemEventDate,setItemEventDate] = useState(0);

    const[ItemMode,setItemMode] = useState(1);
    const[_ItemSave,setItemSave] = useState([]);
    const[_TotalAmount,setTotalAmount] = useState(0);
    const[_TotalDiscount,setTotalDiscount] = useState(0);

    const[_EventSave,setEventSave] = useState([]);
    const[_Customer,setCustomer] = useState(null);
    const[_Invoice,setInvoice] = useState(0);
    const[_CustomerPopup,setCustomerPopup] = useState(false);
    const [_PackageItemSelectIndex,setPackageItemSelectIndex] = useState(undefined);
    const _ProductView = useRef();

    const [_QuotationRemarkList,setQuotationRemarkList] = useState([]);
    const [_QuotationRemarkID,setQuotationRemarkID] = useState(0);
    const [_QuotationRemark,setQuotationRemark] = useState(null);

    const _PromotionItemRef = useRef();
    const [_PromoitionList,setPromotionList] = useState([]);
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(){
            setQuotationRemarkList([]);
            setPromotionList([]);
            _LoadQuotationRemark();
            _ClearValuAction();
            setCustomerPopup(false);
            setPackageItemSelectIndex(undefined);
            setModalState(true);
        }
      }));

      const _LoadQuotationRemark = async()=>{
        setQuotationRemarkList([]);
        API({method : "get",url:'/Search/QuotationRemark/List'}).then(res=>{
            if(res.data.Status){
                setQuotationRemarkList(res.data.Data);
            }

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }
    const _GetPackageGroupList = ()=>{
        setPackageGroupList([]);
        API({method : "get",url:"/Search/Package/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setPackageGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetPackageList = (GroupID)=>{
        setPackageList([]);
        API({method : "get",url:"/Search/Package?PackageGroupID="+GroupID+"&Event="+_ItemEventDate}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setItemRefID(GroupID);
            setPackageList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetProductTypeList = ()=>{
        setProductTypeList([]);
        API({method : "get",url:"/Search/Product/Type"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setProductTypeList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetProductList = (TypeID)=>{
        setProductList([]);
        API({method : "get",url:"/Search/Product?ProductTypeID="+TypeID+"&Event="+_ItemEventDate}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setItemRefID(TypeID);
            setProductList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _CloseModel = ()=>{
        setModalState(false);
        if(props.callback != undefined){
            try{
                props.callback();
            }catch(e){}
        }
    }
    const _CloseItemModel = ()=>{
        setModalItemState(false);
    }
    const _Save = async()=>{
        if(getValues('CustomerID') == undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุลูกค้า'
            });
            return;
        }
        if(getValues('ContactPhoneMain') == undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุเบอร์โทร'
            });
            return;
        }
        if(isNaN(new Date(EventDate))){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุที่วันที่จัดงาน'
            });
            return;
        }

        if(getValues('EventAddress') === undefined || getValues('EventAddress') === null || getValues('EventZipCode') === undefined || getValues('EventZipCode') === null|| getValues('EventDistrictID') === undefined || getValues('EventDistrictID') === null || getValues('EventDistrictID').SubdistrictId === undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุเขตสถานที่จัดงาน'
            });
            return;
        }
        const _EventSaveList = [... _EventSave];
        if(_EventSaveList.length == 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจวันจัดงาน'
            });
            return;
        }
        let _EventObjList = [];

        _EventSaveList.find((v,i)=>{
                const _Obj = {
                    EventDate : DateFormatJSON(v.EventDate,false),
                    StandbyTime : (v.StandbyTime != null ? DateFormatJSON(v.EventDate,false)+' '+v.StandbyTime:null),
                    EventStart : (v.EventStart != null ? DateFormatJSON(v.EventDate,false)+' '+v.EventStart:null),
                    EventEnd : (v.EventEnd != null ? DateFormatJSON(v.EventDate,false)+' '+v.EventEnd:null),
                }
                _EventObjList.push(_Obj);
        });

        const _ItemSaveList = [... _ItemSave];
        if(_ItemSaveList.length == 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบรายการ'
            });
            return;
        }
        let _TotalAmount = 0;
        _ItemSaveList.find((v,i)=>{
            _TotalAmount += v.TotalAmount;
        });

        if(_TotalAmount === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบรายการ'
            });
            return;
        }
        let _reqObj = {
            QuotationType : getValues('QuotationType'),
            CustomerID : _Customer.CustomerID,
            ContactPhoneMain : CleanMobile(getValues('ContactPhoneMain')),
            ContactPhoneSpare : CleanMobile(getValues('ContactPhoneSpare')),
            EventDate:DateFormatJSON(EventDate,false),
            AccountType : AccountType,
            TotalAmount : _TotalAmount,
            EventAddress:(getValues('EventAddress') == undefined ? null : getValues('EventAddress')),
            EventDistrictID:(getValues('EventDistrictID') == undefined ? null : getValues('EventDistrictID').SubdistrictId),
            EventZipCode:(getValues('EventZipCode') == undefined ? null : getValues('EventZipCode')),
            EventLocation:(getValues('EventLocation') == undefined ? null : getValues('EventLocation')),
            ContactName:_Customer.Name,
            QuotationRemarkID : _QuotationRemarkID,
            Remark:(getValues('Remark') == undefined ? null : getValues('Remark')),
            Note:(getValues('Note') == undefined ? null : getValues('Note')),
            LineChatURL:(getValues('LineChatURL') == undefined ? null : getValues('LineChatURL')),
            FacebookURL:(getValues('FacebookURL') == undefined ? null : getValues('FacebookURL')),
            FacebookURL:(getValues('CalendarURL') == undefined ? null : getValues('CalendarURL')),
            Events : _EventObjList,
            Items : _ItemSaveList,
            Invoice:(_Invoice === 1?true:false),
            InvoiceName:(getValues('InvoiceName') == undefined ? null : getValues('InvoiceName')),
            InvoiceTaxID:(getValues('InvoiceTaxID') == undefined ? null : getValues('InvoiceTaxID')),
            InvoiceBranch:(getValues('InvoiceBranch') == undefined ? null : getValues('InvoiceBranch')),
            InvoiceAddress:(getValues('InvoiceAddress') == undefined ? null : getValues('InvoiceAddress')),
            InoiceZipCode:(getValues('InvoiceZipCode') == undefined ? null : getValues('InvoiceZipCode')),
            Promotions:[]
        };
        _PromoitionList.map((v,i)=>{
            _reqObj.Promotions.push(v.PromotionID);
        });
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Quotation/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setModalState(false);
                _ClearValuAction();
                if(props.update != undefined){
                    try{
                        props.update(res.data.Data);
                    }catch(e){
                        console.log(e);
                    }
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }

    const _ItemDelete = (Index) =>{
        const _Item = [..._ItemSave];
        let _ItemN = [];
        let _i=0;
        _Item.find((e,i) => {
            if(Index != i){
                _i++;
                e.LineNumber = _i;
                _ItemN.push(e);
            }
        });

        _ItemTotalCal(_ItemN);
    }

    const _ItemUpdate = (Index,ItemObj) =>{
        const _Item = [..._ItemSave];
        let _ItemN = [];
        _Item.find((e,i) => {
            if(Index === i){
                if(ItemObj.FOC){
                    ItemObj.DiscountAmount = ItemObj.Quantity*ItemObj.UnitPrice;
                    ItemObj.TotalAmount =  ItemObj.Quantity*ItemObj.UnitPrice;
                }else{
                    ItemObj.TotalAmount = ItemObj.Quantity*ItemObj.UnitPrice;
                    ItemObj.DiscountAmount = 0;
                }
                _ItemN.push(ItemObj);
            }else{
                _ItemN.push(e);
            }

        });

        _ItemTotalCal(_ItemN);
    }
    const _ItemTotalCal = (ItemList)=>{
        let _Total = 0;
        let _Discount = 0;
        ItemList.find((e,i) => {
            _Total += e.TotalAmount;
            _Discount += e.DiscountAmount;
        });
        setTotalAmount(_Total);
        setTotalDiscount(_Discount);
        setItemSave(ItemList);

    }

    const _ItemPrice = (Index,ItemObj)=>{
        if(ItemObj.RefType != 1) return;
        API({method : "get",url:"/Search/Package/"+ItemObj.RefID+"/Price?Quantity="+ItemObj.Quantity}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            ItemObj.PackagePriceId = res.data.Data.PackagePriceId
            ItemObj.UnitPrice = res.data.Data.Price;
            if(res.data.Data.Description != null && res.data.Data.Description.length != 0){
                ItemObj.Description = res.data.Data.Description;
            }
            _ItemUpdate(Index,ItemObj);
        }).catch(res => {
            return;
        });
    }
    const _NewItem = ()=>{

        if(_EventSave.length === 0){
            Swal.fire({
                icon: 'error',
                title: 'ไม่พบข้อมูลวันจัดงาน',
            });
            return;
        }
        setItemEventDate(0);
        setPackageList([]);
        setProductList([]);
        _GetPackageGroupList();
        _GetProductTypeList();
        //_GetPackageList(0);
        
        setModalItemState(true);
    }
    const _NewItemPackage = (PackageObj)=>{
        let _Item = {
            LineNumber:_ItemSave.length+1,
            EventKey:_ItemEventDate,
            RefType:1,
            RefID:PackageObj.PackageId,
            Name : PackageObj.PackageName,
            Description : PackageObj.Description,
            GroupName : PackageObj.PackageGroupName,
            GroupId:PackageObj.PackageGroupId,
            UnitPrice : PackageObj.Price,
            PackagePriceId : 0,
            TotalAmount:0,
            DiscountAmount:0,
            FOC:false,
            Promotion:null,
            Remark:PackageObj.Remark

        }
        let _ItemsList = [..._ItemSave,_Item];
        _ItemTotalCal(_ItemsList);
        setModalItemState(false);
    }
    const _NewItemProduct = (ProductObj)=>{
        let _Item = {
            LineNumber:_ItemSave.length+1,
            EventKey:_ItemEventDate,
            RefType:2,
            RefID:ProductObj.ProductId,
            Name : ProductObj.ProductName,
            Description : ProductObj.Description,
            GroupName : ProductObj.ProductTypeName,
            GroupId:ProductObj.ProductTypeId,
            UnitPrice : ProductObj.Price,
            PackagePriceId : 0,
            TotalAmount:0,
            DiscountAmount:0,
            FOC:false,
            Promotion:null,
            Remark:null

        }
        let _ItemsList = [..._ItemSave,_Item];
        _ItemTotalCal(_ItemsList);
        setModalItemState(false);
    }
    const _NewEvent = ()=>{
        let _EventList = [..._EventSave];
        let _Error = false;
        console.log(EventDate);
        if(EventDate === null || EventDate === undefined  || EventDate === ''){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุวันที่จัดงาน',
            });
            return;
        }
        const _EventDate = moment(EventDate);
        _EventList.find((v,i)=>{
            if(v.EventDate === EventDate && _StartTime === ""){
                _Error = true;
            }
            if(v.EventDate === EventDate && _StartTime === v.EventStart){
                _Error = true;
            }
        });
        if(_Error){
            Swal.fire({
                icon: 'error',
                title: 'วันที่และเวลาจัดงานซ้ำ',
            });
            return;
        }

        let _EventDateKey = DateFormatJSON(EventDate,false)+(_StartTime === "" || _StartTime === null ?" 00:00":" "+_StartTime);

        const _EventObj = {
            EventKey : _EventDateKey,
            EventDate : EventDate,
            StandbyTime:(_StandbyTime === ""?null:_StandbyTime),
            EventStart:(_StartTime === ""?null:_StartTime),
            EventEnd:(_EndTime === ""?null:_EndTime),
        }
        _EventList.push(_EventObj);
        setEventSave(_EventList);
    }
    const _GetEvent = (EventKey)=>{
        let _retObj = null;
        _EventSave.find((v,i)=>{
                if(v.EventKey === EventKey){
                    _retObj = v;
                }
        });
        return _retObj;
    }

    const _GetItemByEventKey = (EventKey) =>{
        let _Items = [];
        _ItemSave.find((v,i)=>{
                if(v.EventKey === EventKey)
                    _Items.push(v);
        });
        return _Items;
    }

    const _EventDelete = (Index) =>{
        const _Item = [..._EventSave];

        const _EventItem = _Item[Index];
        if(_GetItemByEventKey(_EventItem.EventKey).length > 0){
            Swal.fire({
                icon: 'error',
                title: 'ไม่สามารถลบได้',
            });
            return;
        }
        let _ItemN = [];
        let _i=0;
        _Item.find((e,i) => {
            if(Index != i){
                _i++;
                e.LineNumber = _i;
                _ItemN.push(e);
            }
        });

        setEventSave(_ItemN);
    }
    const _ItemChangeEvent = (EventKey)=>{
        setItemEventDate(EventKey);
        if(EventKey === '0'){
            setPackageList([]);
            setProductList([]);
            return;
        }
        if(ItemMode === 1 && _ItemRefID > 0){
            _GetPackageList(_ItemRefID);
        }
        if(ItemMode === 2 && _ItemRefID > 0){
            _GetProductList(_ItemRefID);
        }
    }
    const _ClearValue=()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการล้างข้อมูล หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ClearValuAction();
        });
    }
    const _ClearValuAction=()=>{
        reset();
        setStartTime(null);
        setEndTime(null);
        setStandbyTime(null);
        setCustomer(null);
        setEventDate(moment().add(3, 'day').toDate());
        setValue('CustomerID','');
        setValue('EventDistrictID','');
        setEventSave([]);
        setValue('CustomerName',null);
        setValue('AccountType',1);
        setValue('LineChatURL','');
        setValue('EventAddress','');
        setValue('Remark','');
        setItemSave([]);
        setEventDate('');
        setTotalAmount(0);
        setTotalDiscount(0);
        
        setQuotationRemarkID(0);
        setQuotationRemark(null);
    }
    const _UpdateCustomer=(e)=>{
        setCustomer(e);
        setValue('ContactPhoneMain',e.Mobile);
        setValue('AccountType',e.AccountType);

        if(e.AccountType == 1){
            setInvoice(1);
        }else{
            setInvoice(2);
        }

        setValue('InvoiceName',e.TaxName);
        setValue('InvoiceTaxID',e.TaxID);
        setValue('InvoiceBranch',e.TaxBranchName);
        setValue('InvoiceAddress',e.TaxAddress);
        setValue('InvoiceZipCode',e.TaxZipCode);
    }
    const _EventKeyDateFormat = (val) =>{
        const _val = moment(val,"yyyy-MM-DD HH:mm").toDate();
        if(_val.getHours() === 0 && _val.getMinutes() ===0)
            return DateThaiFormat(_val,false);
        else
            return DateThaiFormat(_val,false)+'<br/>'+TimeFormat(_val);
    }
    const _PromotionItem = ()=>{
        _PromotionItemRef.current?.NewItem();
        
    }
    const _PromotionNewItem = (v)=>{
        let _refAR = [... _PromoitionList];
        _refAR.push(v);

        setPromotionList(_refAR);
    }
    const _PromotionDelete = (index)=>{
        const _refAR = [... _PromoitionList];
        let _newAR = [];
        _refAR.find((v,i)=>{
            if(i != index) _newAR.push(v);
            
        });
        setPromotionList([... _newAR]);
    }
    const _CustomerInfo = ()=>{
        console.log('Customer Open');
        setCustomerPopup(true);
    }

    const _PackageItemClick = (Index)=>{
        if(Index === _PackageItemSelectIndex)
            setPackageItemSelectIndex(undefined);
        else
           setPackageItemSelectIndex(Index);
    }
    const _PackageChangeClick = (d)=>{
        const _Items = [..._ItemSave];
        let _ItemN = [];
        let _SelectObj = undefined;
        _Items.find((v,i)=>{
            if(_PackageItemSelectIndex === i) _SelectObj = v;
        });
        let _curIndex = 0;
        if(d=== 1)
            _curIndex = _PackageItemSelectIndex+1;
        if(d=== 2)
            _curIndex = _PackageItemSelectIndex-1;
        if(_curIndex >= _Items.length) return;
        if(_curIndex <0) _curIndex = 0;
        let _Update = false;
        _Items.find((v,i)=>{
            if(d=== 2 && _curIndex === i){
                _SelectObj.LineNumber = _ItemN.length+1;
                _ItemN.push(_SelectObj);
                _Update = true;
            }
            if(i != _PackageItemSelectIndex){
                let _Obj = v;
                _Obj.LineNumber =  _ItemN.length+1;
                _ItemN.push(_Obj);
            } 
            if(d=== 1 && _curIndex === i){
                _SelectObj.LineNumber = _ItemN.length+1;
                _ItemN.push(_SelectObj);
                _Update = true;
            }
        });
        if(_Update === false) {
            _SelectObj.LineNumber = _ItemN.length+1;
            _ItemN.push(_SelectObj);
        }
        setPackageItemSelectIndex(_curIndex);
        setItemSave(_ItemN);
    }
    const _ProductInfo = (v)=>{
        _ProductView.current?.Show(v.RefType,v.RefID,v.Name);
    }

    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">สร้างใบเสนอราคา</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{_CloseModel()}}></button>
            </div>

            <div className='modal-body'>
                <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                    <div className='row mt-3'>
                        <div className="col-md-6">
                            <label className="form-label">รหัสลูกค้า</label>
                            <Controller name="CustomerID" control={control} rules={{required:false}} render={({field})=>(
                                <AsyncSelect 
                                    name={field.name}
                                    value={field.value}
                                    cacheOptions 
                                    defaultOptions 
                                    placeholder={'ค้นหา ลูกค้า'} 
                                    loadOptions={(e)=>(SearchCustomer(e))} 
                                    onChange={(e)=>{field.onChange(e); _UpdateCustomer(e);}}
                                    getOptionLabel={(e)=> {return e.CustomerCode+' | '+e.Name}} 
                                    getOptionValue={(e)=>e.SubdistrictId} />
                            )} />
                        </div>
                        <div className="col-md-2">
                            <button type="button" className={(_Customer != null ?'btn btn-info btn-sm text-white':'btn btn-secondary btn-sm text-white')} style={{'marginTop':'34px'}} disabled={(_Customer != null ? false:true)} onClick={()=>{_CustomerInfo()}}>ข้อมูลลูกค้า</button>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">ยอดสั่งซื้อที่ผ่านมา</label>
                            <input type='text' className="form-control text-end" autoComplete="off" readOnly={true} value={_Customer != null?NumberFormat(_Customer.OrderAmount):"0"}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">จำนวนใบเสนอราคา</label>
                            <input type='text' className="form-control text-end" placeholder="เบอร์โทร" autoComplete="off" readOnly={true} value={_Customer != null?NumberFormat(_Customer.QuotationNum):"0"}/>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-md-3">
                            <label className="form-label">เบอร์โทร</label>
                            <input type='text' className="form-control" placeholder="เบอร์โทร" autoComplete="off" readOnly={true}   {...register("ContactPhoneMain", { required: false })}/>
                        </div>

                        <div className="col-md-3">
                            <label className="form-label">เบอร์โทร สำรอง</label>
                            <input type='text' className="form-control" placeholder="เบอร์โทร สำรอง" autoComplete="off"   {...register("ContactPhoneSpare", { required: false })}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">ประเภท</label>
                            <select className='form-control text-center' onChange={(e)=>{setAccountType(e.target?.value); if(e.target?.value === 2) setInvoice('0');}} value={AccountType} defaultValue={AccountType} >
                                <option value={0}>เลือกประเภท</option>
                                <option value={1} selected={(AccountType == 1 ? true:false)}>บริษัท</option>
                                <option value={2} selected={(AccountType == 2 ? true:false)}>ร้าน</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">ใบกำกับภาษี</label>
                            <select className='form-control text-center' onChange={(e)=>{setInvoice(e.target?.value);}} defaultValue={_Invoice} >
                                    <option value={1} selected={(_Invoice == 1 ? true:false)}>ต้องการ</option>
                                    <option value={0} selected={(_Invoice == 0 ? true:false)}>ไม่ต้องการ</option>
                                </select>
                            {errors.AccountType && errors.Remark.type == 'required' && <small className="form-text alert alert-danger">โปรดระบุประเภท</small> }
                        </div>
                    </div>
                    {(_Invoice == 1 ? 
                    <>
                    <div className='row mt-5'>
                        <div className="col-md-6">
                            <label className="form-label">ชื่อธุรกิจ</label>
                                <input type='text' className="form-control" placeholder="ชื่อธุรกิจ / หน่วยงาน / ชื่อบริษัท" autoComplete="off"   {...register("InvoiceName", { required: false })}/>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">เลขประจำตัวผู้เสียภาษี</label>
                                <input type='text' className="form-control" placeholder="เลขประจำตัวผู้เสียภาษี" autoComplete="off"  {...register("InvoiceTaxID", { required: false })}/>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">สำนักงาน/สาขา</label>
                                <input type='text' className="form-control" placeholder="สำนักงาน/สาขา" autoComplete="off"  {...register("InvoiceBranch", { required: false })}/>
                        </div>
                        
                    </div>
                    <div className='row mt-3 mb-5'>
                        <div className="col-md-10">
                            <label className="form-label">ที่อยู่</label>
                            <Controller name="InvoiceAddress" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">รหัสไปรษณีย์</label>
                            <input type='text' maxLength={5} className="form-control text-center" placeholder="รหัสไปรษณีย์" autoComplete="off" {...register("InvoiceZipCode", { required: false })}/>
                        </div>
                    </div>
                    </>
                    :"")}        
                    <div className='row mt-3'>
                        <div className="col-md-5">
                            <label className="form-label">สถานที่จัดงาน</label>
                            <Controller name="EventAddress" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                        <div className="col-md-5">
                            <label className="form-label">เขต</label>
                            <Controller name="EventDistrictID" control={control} rules={{required:false}} render={({field})=>(
                                <AsyncSelect 
                                    name={field.name}
                                    value={field.value}
                                    cacheOptions 
                                    defaultOptions 
                                    placeholder={'ค้นหา ตำบล / อำเภอ / จังหวัด'} 
                                    loadOptions={(e)=>SearchSubDistrict(e)} 
                                    onChange={(e)=>field.onChange(e)}
                                    getOptionLabel={(e)=> e.FullName} 
                                    getOptionValue={(e)=>e.SubdistrictId} />
                            )} />

                            {errors.EventDistrictID && errors.EventDistrictID.type == 'required' && <small className="form-text alert alert-danger">โปรดระบุเขต</small> }
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">รหัสไปรษณีย์</label>
                            <input type='text' maxLength={5} className="form-control text-center" placeholder="รหัสไปรษณีย์" autoComplete="off" {...register("EventZipCode", { required: false })}/>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-4">
                            <label className="form-label">พิกัด GPS</label>
                            <input type='text' className="form-control" placeholder="พิกัด GPS" autoComplete="off" {...register("EventLocation", { required: false })}/>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">ประเภทใบเสนอราคา</label>
                            <Controller name="QuotationType" control={control} rules={{required:false}} render={({field})=>(
                                <select className='form-control text-center'onChange={(e)=>field.onChange(e)} value={field.value}  defaultValue={field.value} >
                                    <option value={0}>เลือกประเภทใบเสนอราคา</option>
                                    {QuotationType.map((v,i)=>(
                                        <option key={i} value={v}>{QuotationTypeName(v)}</option>
                                    ))}
                                </select>
                        )} />

                            
                        </div>
                    </div>

                    <div className='row mt-5 justify-content-center'>
                        <div className="col-md-2">
                            <label className="form-label">วันที่จัดงาน</label>
                            <ThaiDatePicker minDate={moment().add(1,'days').toDate()} inputProps={{displayFormat: "DD/MM/YYYY",className:'text-center form-control' }} value={EventDate} onChange={(c,b)=>{
                                setEventDate(c)
                            }}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">เวลาถึงหน้างาน</label>
                            <TimePicker onChange={(e)=>{setStandbyTime(e);}} locale='sv-sv' disableClock={true} value={_StandbyTime}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">เวลาเริ่มบริการ</label>
                            <TimePicker onChange={(e)=>{setStartTime(e);}} locale='sv-sv' disableClock={true} value={_StartTime}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">เวลาสิ้นสุดบริการ</label>
                            <TimePicker onChange={(e)=>{setEndTime(e);}} locale='sv-sv' disableClock={true} value={_EndTime}/>

                        </div>
                        <div className="col-md-2">
                            <button type="button" className='btn btn-success btn-sm' style={{'marginTop':'32px'}} onClick={()=>{_NewEvent();}}>+ เพิ่มวันจัดงาน</button>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-12'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>วันที่จัดงาน</th>
                                        <th className='text-center'>เวลาถึงหน้างาน</th>
                                        <th className='text-center'>เวลาเริ่มบริการ</th>
                                        <th className='text-center'>เวลาสิ้นสุดบริการ</th>
                                        <th className='text-center'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_EventSave.map((v,i)=>(
                                        <tr key={i}>
                                            <td className='text-center'>{DateThaiFormat(v.EventDate,false)}</td>
                                            <td className='text-center'>{v.StandbyTime}</td>
                                            <td className='text-center'>{v.EventStart}</td>
                                            <td className='text-center'>{v.EventEnd}</td>
                                            <td className='text-end'>
                                                <div className='btn-group btn-group-sm'>
                                                <button type="button" className='btn btn-danger' onClick={()=>{_EventDelete(i);}}>ลบ</button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-12'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>ลำดับ</th>
                                        <th className='text-center'>วันที่จัดงาน</th>
                                        <th className='text-center'>รายการ</th>
                                        <th className='text-center'>รายละเอียด</th>
                                        <th className='text-center' style={{'width':'100px'}}>จำนวน</th>
                                        <th className='text-center'style={{'width':'120px'}}>ราคาต่อหน่วย</th>
                                        <th className='text-center'style={{'width':'150px'}}>ราคารวม</th>
                                        <th className='text-end'>
                                            <div className='btn-group btn-group-sm'>
                                                <button type="button" className='btn btn-success btn-sm' onClick={()=>{_NewItem();}}>+ เพิ่มรายการ</button>
                                                <button type="button" className='btn btn-primary btn-sm' onClick={()=>{_PackageChangeClick(1)}} disabled={(_PackageItemSelectIndex === undefined ? true : false)}>+</button>
                                                <button type="button" className='btn btn-primary btn-sm' onClick={()=>{_PackageChangeClick(2);}} disabled={(_PackageItemSelectIndex === undefined ? true : false)}>-</button>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                {_ItemSave.map((v,i)=>(
                                       <tbody key={i}>
                                            <tr onClick={(e)=>{_PackageItemClick(i);}} className={(_PackageItemSelectIndex === i?'table-active':'')}>
                                                <td className='text-center'>{v.LineNumber}</td>
                                                <td className='text-center'>
                                                    {parse(_EventKeyDateFormat(v.EventKey))}
                                                </td>
                                                <td className='text-left'>{v.Name}</td>
                                                <td className='text-left'>
                                                    <textarea className='form-control' defaultValue={v.Description} value={v.Description}onChange={(e)=>{
                                                        v.Description = e.target?.value;
                                                        _ItemUpdate(i,v);
                                                    }}></textarea>
                                                </td>
                                                <td className='text-left'>
                                                    <input type='text' className='form-control text-center' defaultValue={v.Quantity} value={v.Quantity} onChange={(e)=>{ 
                                                        if(isNaN(Number(e.target?.value))) return; 
                                                            v.Quantity = Number(e.target?.value);
                                                            _ItemUpdate(i,v);
                                                            _ItemPrice(i,v);
                                                        }} 
                                                        onKeyDown={(e)=>{
                                                            if(e.key === 'Backspace' || e.key === '.' || e.key === 'ArrowRight' || e.key === 'ArrowLeft' || e.key === 'Tab') return;
                                                            if(isNaN(Number(e.key))) e.preventDefault();
                                                        }}/>
                                                </td>
                                                <td className='text-end'>
                                                    <input type='text' className='form-control text-end' defaultValue={v.UnitPrice} value={v.UnitPrice} onChange={(e)=>{ 
                                                        if(isNaN(Number(e.target?.value))) return; 
                                                            v.UnitPrice = Number(e.target?.value);
                                                            _ItemUpdate(i,v);
                                                        }} 
                                                        onKeyDown={(e)=>{
                                                            if(e.key === 'Backspace' || e.key === '.' || e.key === 'ArrowRight' || e.key === 'ArrowLeft' || e.key === 'Tab') return; 
                                                            if(isNaN(Number(e.key))) e.preventDefault();
                                                        }}/>
                                                    </td>
                                                <td className='text-end'>{(v.FOC?'-'+NumberFormat(v.DiscountAmount):NumberFormat(v.TotalAmount))}</td>
                                                <td className='text-end'>
                                                    <div className='btn-group btn-group-sm'>
                                                    <button type="button" className={(v.FOC?'btn btn-success':'btn btn-secondary')} onClick={()=>{
                                                        if(v.FOC) v.FOC = false; else v.FOC = true;
                                                        _ItemUpdate(i,v);
                                                    }}>ของแถม</button>
                                                    <button type="button" className='btn btn-danger' onClick={()=>{_ItemDelete(i);}}>ลบ</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr onClick={(e)=>{_PackageItemClick(i);}} className={(_PackageItemSelectIndex === i?'table-active':'')} >
                                                <td></td>
                                                <td colSpan={6}>
                                                    <textarea className='form-control' readOnly={true} value={(v.Remark != null ? v.Remark : '')}></textarea>
                                                </td>
                                                <td className='text-end'>
                                                    <div className='btn-group btn-group-sm'>
                                                        <button type='button' className='btn btn-info text-white' onClick={()=>{_ProductInfo(v);}}>รายละเอียด</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                            </table>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-lg-8 table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>รหัสโปรโมชั่น</th>
                                        <th className='text-center'>โปรโมชั่น</th>
                                        <th className='text-center'>รายละเอียด</th>
                                        <th className='text-center'>ส่วนลด</th>
                                        <th className='text-end'>
                                        <button type="button" className='btn btn-success btn-sm'  onClick={()=>{_PromotionItem();}}>+ เพิ่มรายการ</button>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(_PromoitionList.map((v,i)=>(
                                        <tr key={i}>
                                            <td className='text-center'>{v.PromotionCode}</td>
                                            <td className='text-center'>{v.PromotionName}</td>
                                            <td className='text-center'>{v.Description}</td>
                                            <td className='text-center'>{v.DiscountValue}</td>
                                            <td className='text-center'>
                                                <div className='btn-group btn-group-sm'>
                                                    <button type='button' className='btn btn-danger' onClick={(e)=>{_PromotionDelete(i);}}>ลบ</button>
                                                </div>
                                            </td>
                                        </tr>
                                    )))}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-lg-4'>
                            <div className='row mt-3 justify-content-end'>
                                <div className='col-lg-6 text-end fs-5'>รวมเป็นเงิน</div>
                                <div className='col-lg-6 text-end'>
                                    <h4 className='fs-5 text-danger'>{NumberFormat(_TotalAmount)}</h4>
                                </div>
                            </div>
                            <div className='row mt-1 justify-content-end'>
                                <div className='col-lg-6 text-end fs-5'>ส่วนลด</div>
                                <div className='col-lg-6 text-end'>
                                    <h4 className='fs-5 text-danger'>{NumberFormat(_TotalDiscount)}</h4>
                                </div>
                            </div>
                            {(AccountType == 1 ? <>
                                <div className='row mt-1 justify-content-end'>
                                    <div className='col-lg-6 text-end fs-5'>ภาษีมูลค่าเพิ่ม 7%</div>
                                    <div className='col-lg-6 text-end'>
                                        <h4 className='fs-5 text-danger'>{NumberFormat((((_TotalAmount-_TotalDiscount)*7)/100))}</h4>
                                    </div>
                                </div>
                                <div className='row mt-3 justify-content-end'>
                                    <div className='col-lg-6 text-end'><h4>จำนวนเงินทั้งสิ้น</h4></div>
                                    <div className='col-lg-6 text-end'>
                                        <h4 className='fs-4 text-success'>{NumberFormat((((_TotalAmount-_TotalDiscount)*7)/100)+(_TotalAmount-_TotalDiscount))}</h4>
                                    </div>
                                </div>
                            </>:
                                <div className='row mt-3 justify-content-end'>
                                    <div className='col-lg-6 text-end'><h4>จำนวนเงินทั้งสิ้น</h4></div>
                                    <div className='col-lg-6 text-end'>
                                        <h4 className='fs-4 text-success'>{NumberFormat(_TotalAmount-_TotalDiscount)}</h4>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    
                    
                    <div className='row mt-2 mb-2'>
                        <div className='col-md-6'>
                            <label className="form-label title-label">หมายเหตุ</label>
                            <select className='form-control' value={_QuotationRemarkID} onChange={(e)=>{setQuotationRemarkID(e.target.value);setQuotationRemark(null);_QuotationRemarkList.find((v,i)=>{ if(v.QuotationRemarkId === Number(e.target.value)){setQuotationRemark(v);}})}}>
                                <option value={0} className='text-center'>กรุณาเลือกหมายเหตุ</option>
                                {_QuotationRemarkList.map((v,i)=>(
                                    <option value={v.QuotationRemarkId}>{v.QuotationRemarkName}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-md-6'>
                            <label className="form-label title-label">รายละเอียดหมายเหตุ</label>
                            <textarea className='form-control' readOnly={true} value={(_QuotationRemark != null ? _QuotationRemark.Remark:'')}></textarea>
                        </div>
                    </div>
                    
                    <div className='row mt-4'>
                        <div className="col-md-6">
                            <label className="form-label">หมายเหตุ พิเศษ</label>
                            <Controller name="Remark" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Note</label>
                            <Controller name="Note" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>

                    <div className='row mt-2'>
                    <div className="col-md-4">
                            <label className="form-label">Facebook</label>
                            <Controller name="FacebookURL" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Line Chat</label>
                            <Controller name="LineChatURL" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Calendar</label>
                            <Controller name="CalendarURL" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-md-12 mt-4 text-end'>
                            <div className='btn-group btn-group-lg'>
                                <button type="button" className='btn btn-danger' onClick={()=>{_ClearValue();}}>ล้างข้อมูล</button>
                                <button type="submit" className='btn btn-success'>บันทึก</button>
                            </div>
                        </div>
                    </div>                 
                </Form>
            </div>
        </Modal>
        <Modal isOpen={ModalItemState} className={'modal-xl'} centered={true} unmountOnClose={true}>
        <div className="modal-header">
                <h5 className="modal-title">เลือกรายการแพ็คเกจ / สินค้า</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{_CloseItemModel()}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'500px'}}>
                <div className='row justify-content-between'>
                    <div className='col-lg-3'>
                        <div className="btn-group">
                            <button className={(ItemMode === 1 ? "btn btn-primary active": "btn btn-secondary")} onClick={()=>{setItemMode(1);}}>แพ็คแก็จ</button>
                            <button className={(ItemMode === 2 ? "btn btn-primary active": "btn btn-secondary")} onClick={()=>{setItemMode(2);}}>สินค้า</button>
                        </div>
                    </div>
                    <div className='col-lg-6 align-self-end align-self-lg-end text-end'>
                        <div className='d-flex'>
                            <div className='form-group'>
                            <select className='form-control' style={{'width':'200px','marginRight':'20px'}} onChange={(e)=>{_ItemChangeEvent(e.currentTarget?.value);}}>
                                <option value={0} selected={true} className='text-center'>เวลาจัดงาน</option>
                                {_EventSave.map((v,i)=>(
                                    <option key={i} value={(v.EventKey)}>{DateFormat(v.EventDate,false)+(v.EventStart != null ? ' '+v.EventStart:'')}</option> 
                                ))}
                            </select>
                            </div>
                            <div className='form-group'>
                            {(ItemMode === 1 ?
                                <select className='form-control' disabled={(_ItemEventDate === 0 ? true : false)} style={{'width':'200px'}} onChange={(e)=>{_GetPackageList(e.currentTarget?.value);}}>
                                    <option value={0} selected={true} className='text-center'>เลือกกลุ่ม</option>
                                    {PackageGroupList.map((v,i)=>(
                                        <option key={i} value={(v.PackageGroupId)}>{v.PackageGroupName}</option> 
                                    ))}
                                </select>
                            :"")}
                            {(ItemMode === 2 ?
                                <select className='form-control' disabled={(_ItemEventDate === 0 ? true : false)} style={{'width':'200px'}} onChange={(e)=>{_GetProductList(e.currentTarget?.value);}}>
                                    <option value={0} selected={true} className='text-center'>เลือกประเภท</option>
                                    {_ProductTypeList.map((v,i)=>(
                                        <option key={i} value={(v.ProductTypeId)}>{v.ProductTypeName}</option> 
                                    ))}
                                </select>
                            :"")}
                            </div>
                        </div>
                        
                    
                    </div>
                </div>
                {(ItemMode === 1 ?<div className='row mt-3'>
                    <div className='col-lg-12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='text-center'>รหัส</th>
                                    <th className='text-center'>แพ็คแก็จ</th>
                                    <th className='text-center'>รายละเอียด</th>
                                    <th className='text-center'>ราคาต่อหน่วย</th>
                                    <th className='text-center'>จำนวนงาน</th>
                                    <th className='text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {_PackageList.map((v,i)=>(
                                 <tr key={i}>
                                    <td className='text-center'>{v.PackageCode}</td>
                                    <td className='text-left'>{v.PackageName}</td>
                                    <td className='text-left'>{v.Description}</td>
                                    <td className='text-end'>{NumberFormat(v.Price)}</td>
                                    <td className='text-center'> {NumberFormat(v.QuoteQuantity)} / {NumberFormat(v.DepositQuantity)} / {NumberFormat(v.QuantityLimit)}</td>
                                    <td className='text-center'>
                                        <button className='btn btn-success btn-sm' onClick={()=>{_NewItemPackage(v);}}>เลือก</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>:"" )}
                {(ItemMode === 2 ?<div className='row mt-3'>
                    <div className='col-lg-12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='text-center'>สินค้า</th>
                                    <th className='text-center'>รายละเอียด</th>
                                    <th className='text-center'>ราคาต่อหน่วย</th>
                                    <th className='text-center'>จำนวนงาน</th>
                                    <th className='text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {_ProductList.map((v,i)=>(
                                 <tr key={i}>
                                    <td className='text-left'>{v.ProductName}</td>
                                    <td className='text-left'>{v.Description}</td>
                                    <td className='text-end'>{NumberFormat(v.Price)}</td>
                                    <td className='text-center'> {NumberFormat(v.QuoteQuantity)} / {NumberFormat(v.DepositQuantity)} / {NumberFormat(v.QuantityLimit)}</td>
                                    <td className='text-center'>
                                        <button className='btn btn-success btn-sm' onClick={()=>{_NewItemProduct(v);}}>เลือก</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>:"" )}
            </div>
        </Modal>
        {(_CustomerPopup?
            <NewWindow onOpen={()=>{setCustomerPopup(false);}} url={'Popup/Customer/?'+(_Customer != null ? _Customer.CustomerID:"")} name={(_Customer != null ? _Customer.CustomerID:"")}/>
        :"")}
        <ProductView ref={_ProductView}/>
        <PromotionItem ref={_PromotionItemRef} newitem={(v)=>{_PromotionNewItem(v)}}/>
        </>)
        
});

export default React.memo(QuotationCreate)