function OrderStatus(Status){
    switch(Status){
        case 1: return 'สร้าง';
        case 2 :return 'ส่งใบเสนอราคา - Inquiry for QT';
        case 3 :return 'ติดตาม';
        case 4 :return 'รอ - Hold';
        case 5 :return 'ชำระมัดจำ - Deposit';
        case 6: return 'ยกเลิก - NA';
        case 7: return "รอชำระมัดจำ - W-Deposit";
        case 10 : return "จบงาน";
        default: return '-';
    }
    /**
     1=> Create
2 => Inquiry for QT
3=> Process
4 => Hold
5=> Deposit
6=> NA
     */
} 

function OrderStatusLabel(Status){
    switch(Status){
        case 1: return '<span class="badge bg-secondary">สร้าง</span>';
        case 2 :return '<span class="badge bg-secondary">ส่งใบเสนอราคา - Inquiry for QT</span>';
        case 3 :return '<span class="badge bg-secondary">ติดตาม</span>';
        case 4 :return '<span class="badge bg-info">รอ - Hold</span>';
        case 5 :return '<span class="badge bg-success">ชำระมัดจำ - Deposit</span>';
        case 6 :return '<span class="badge bg-danger">ยกเลิก - NA</span>';
        case 7 :return '<span class="badge bg-warning">รอชำระมัดจำ - W-Deposit</span>';
        case 10 :return '<span class="badge bg-success">จบงาน</span>';
        default: return '-';
    }
    /**
     1=> Create
2 => Inquiry for QT
3=> Process
4 => Hold
5=> Deposit
6=> NA
     */
} 


function QuotationStatusLabel(Status){
    switch(Status){
        case 1: return '<span class="badge bg-secondary">สร้าง</span>';
        case 2 :return '<span class="badge bg-secondary">ส่งใบเสนอราคา - Inquiry for QT</span>';
        case 3 :return '<span class="badge bg-secondary">ติดตาม</span>';
        case 4 :return '<span class="badge bg-info">รอ - Hold</span>';
        case 5 :return '<span class="badge bg-success">ชำระมัดจำ - Deposit</span>';
        case 6 :return '<span class="badge bg-danger">ยกเลิก - NA</span>';
        case 7 :return '<span class="badge bg-warning">รอชำระมัดจำ - W-Deposit</span>';
        case 10 :return '<span class="badge bg-success">จบงาน</span>';
        default: return '-';
    }

}
function QuotationStatus(Status){
    switch(Status){
        case 1: return 'สร้าง';
        case 2 :return 'ส่งใบเสนอราคา - Inquiry for QT';
        case 3 :return 'ติดตาม';
        case 4 :return 'รอ - Hold';
        case 5 :return 'ชำระมัดจำ - Deposit';
        case 6: return 'ยกเลิก - NA';
        case 7: return "รอชำระมัดจำ - W-Deposit";
        case 10 : return "จบงาน";
        default: return '-';
    }
    /**
     1=> Create
2 => Inquiry for QT
3=> Process
4 => Hold
5=> Deposit
6=> NA
     */
} 
function AppointmentType(Type){
    switch(Type){
        case 0: return 'ไม่นัดหมาย';
        case 1: return 'นัดหมาย';
        case 2: return 'นัดชำระเงิน';
        case 3: return 'นัดหมายติดตาม';
    }
}
const OrderStatusList = [1,2,3,4,5,6,7,10];

const AccountTypeName = (AccountType) =>{
    switch(AccountType){
        case 1 : return 'บริษัท';
        case 2 : return 'ร้าน';
    }
}

const QuotationType = [1,2,3,4];
const QuotationTypeName = (QuotationType) =>{
    switch(QuotationType){
        case 1 : return 'พิธีสงฆ์';
        case 2 : return 'บุพเฟ่';
        case 3 : return 'งานแต่ง';
        case 4 : return 'ซุ้มอาหาร';
        default : return '-';
    }
}

const AccountType = [1,2];
export  {OrderStatus,OrderStatusList,OrderStatusLabel,AppointmentType,QuotationStatus,QuotationStatusLabel,AccountType,AccountTypeName,QuotationType,QuotationTypeName}