import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { Controller, set, useForm } from "react-hook-form";
import { createRoot } from 'react-dom/client';
import { DateFormat,APIURL, DateFormatJSON } from '../../Utils/Function';
import Swal from 'sweetalert2';
import API from '../../API';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
const PageCalendar =()=> {
    const { register, getValues, setValue } = useForm();
    const [_EventList,setEventList] = useState([]);

    useEffect(() => {
        (async () => {
            //await _LoadQuotationStatus();
        })();

        return ()=>{ }
    }, []);
    const handleDateClick = (arg) => {
        alert(arg.dateStr)
      }
    const _LoadEvent = (Start,End)=>{
        API({method : "get",url:"/Calendar/Event?StartDate="+DateFormatJSON(Start,false)+'&EndDate='+DateFormatJSON(End,false)}).then(res=>{
            if (res.status !== 200) {
                return;
            }
    
            if (!res.data.Status) {
                return;
            }
            setEventList(res.data.Data);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: res.data.Message,
            });
            return;
        });
    }

    
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-2'>
                    <div className='col-lg-12'>
                        <FullCalendar
                        plugins={[ dayGridPlugin,interactionPlugin ]}
                        initialView="dayGridMonth"
                        dateClick={handleDateClick}
                        datesSet={(dateInfo) => {
                            _LoadEvent(dateInfo.start,dateInfo.end);
                        }}
                        events={_EventList}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <button type="button" className='btn btn-success' onClick={()=>{}}>ทดสอบ</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}
export default React.memo(PageCalendar)