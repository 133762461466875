import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Table,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import { Controller, useForm } from "react-hook-form";

import { TimeFormat,DateFormat,NumberFormat, DateThaiFormat,DateFormatJSON } from '../../Utils/Function';
import {AccountTypeName} from '../../Utils/Status';
import {SearchSubDistrict} from '../../Utils/Search';

import parse from 'html-react-parser'
import AsyncSelect from 'react-select/async';
import moment from 'moment';

import { ThaiDatePicker } from "thaidatepicker-react";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { QuotationStatusLabel } from '../../Utils/Status';
import QuotationItemNewCard from './QuotationItemNewCard';
import {QuotationPDF,QuotationImage} from './QuotationUtils';
import {ProductView} from '../';
import '../../Assets/CSS/Horapa.css';
import PromotionItem from '../Promotion/PromotionItem';

const QuotationInfoCard = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const _QuotationItemRef = useRef();
    const [ModalState,setModalState] = useState(false);
    const [EventModalState,setEventModalState] = useState(false);
    const [Detail,setDetail] = useState(null);
    const [ViewMode,setViewMode] = useState(1);
    const [Events,setEvents] = useState([]);
    const [_PackageItemSelectIndex,setPackageItemSelectIndex] = useState(null);
    const [_EventIndex,setEventIndex] = useState(undefined);
    const [_EventEdit,setEventEdit] = useState(undefined);
    const [_EventDate,setEventDate] = useState(undefined);
    const [_EventStart,setEventStart] = useState(undefined);
    const [_EventEnd,setEventEnd] = useState(undefined);
    const [_EventStandbyTime,setEventStandbyTime] = useState(undefined);
    const [_QuotationRemarkList,setQuotationRemarkList] = useState([]);
    const [_QuotationRemarkID,setQuotationRemarkID] = useState(0);
    const [_QuotationRemark,setQuotationRemark] = useState(null);
    const [_PromoitionList,setPromotionList] = useState([]);
    const _ProductView = useRef();
    const _PromotionItemRef = useRef();
    const [_PDFImageList,setPDFImageList] = useState([]);
    useEffect(() => {
        (async () => {
            
        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(QuotationID){
            _LoadQuotationRemark();
            _Load(QuotationID);
            setViewMode(1);
            setEvents([]);
            setEventIndex(undefined);
            setEventEdit(undefined);
            setQuotationRemarkList([]);
            setPromotionList([]);
            setQuotationRemarkID(0);
            setQuotationRemark(null);
            setPDFImageList([]);
            //setItems([]);
        },

      }));
    const _Load=async(QuotationID)=>{
        API({method : "get",url:"/Quotation/"+QuotationID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            const _Detail = res.data.Data;
            setDetail(res.data.Data);
            setPromotionList(_Detail.Promotion);
            setEvents(res.data.Data.Events);
            setValue('QuotationType',_Detail.Profile.QuotationType);
            setValue('ContactName',_Detail.Profile.ContactName);
            setValue('ContactPhoneMain',_Detail.Profile.ContactPhoneMain);
            setValue('ContactPhoneSpare',_Detail.Profile.ContactPhoneSpare);
            setValue('EventAddress',_Detail.Profile.EventAddress);
            setValue('EventDistrictID',_Detail.EventSubdistrict);
            setValue('EventZipCode',_Detail.Profile.EventZipCode);
            setValue('EventLocation',_Detail.Profile.EventLocation);
            setQuotationRemarkID((_Detail.Profile.QuotationRemarkId === null ? 0 :_Detail.Profile.QuotationRemarkId));
            if(_Detail.Remark != null){
                setQuotationRemark(_Detail.Remark);
            }
            setValue('Remark',_Detail.Profile.Remark);
            setValue('Note',_Detail.Profile.Note);
            setValue('FacebookUrl',_Detail.Profile.FacebookUrl);
            setValue('LineChatUrl',_Detail.Profile.LineChatUrl);
            setValue('CalendarUrl',_Detail.Profile.CalendarUrl);
            setValue('InvoiceName',_Detail.Profile.InvoiceName);
            setValue('InvoiceAddress',_Detail.Profile.InvoiceAddress);
            setValue('InoiceZipCode',_Detail.Profile.InoiceZipCode);
            setValue('InvoiceTaxID',_Detail.Profile.InvoiceTaxId);
            setValue('InvoiceBranch',_Detail.Profile.InvoiceBranch);
            setPDFImageList([]);
            setModalState(true);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });
    }
    const _LoadQuotationRemark = async()=>{
        setQuotationRemarkList([]);
        API({method : "get",url:'/Search/QuotationRemark/List'}).then(res=>{
            if(res.data.Status){
                setQuotationRemarkList(res.data.Data);
            }

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }
    const _PDF = async() =>{
        if(Detail === null) return;
        QuotationPDF(Detail.QuotationID);
    }
    const _Image = async()=>{
        if(Detail === null) return;
        setPDFImageList([]);

        QuotationImage(Detail.QuotationID).then(r=>{
            setPDFImageList(r.Images);
        });
    }
    const _UpdateItem = (EventIndex,ItemIndex,ItemValue) =>{
        const _EventItem = [... Events];
        let _EventObj = [];
        
        _EventItem.find((EVal,EIndex)=>{
            if(EventIndex != EIndex){
                _EventObj.push(EVal);
                return;
            }
            let _Obj = EVal;
            let _ItemObj = [];
            EVal.Items.find((Val,Index)=>{
                if(ItemIndex != Index){
                    _ItemObj.push(Val);
                    return;
                }
                _ItemObj.push(ItemValue);
            });
            _Obj.Items = _ItemObj;
            _EventObj.push(_Obj);
        });
        setEvents(_EventObj);
        _UpdateCal(_EventObj);
    }
    const _UpdateCal = (Events)=>{

        const _EventItem = [... Events];
        
        let _TotalAmount = 0;
        let _TotalDiscount = 0;
        let _DetailObj = Detail;
        _EventItem.find((EVal,EIndex)=>{

            EVal.Items.find((Val,Index)=>{
                _TotalAmount += Val.TotalAmount;
                _TotalDiscount += Val.DiscountAmount;
            });
        });

        _DetailObj.Profile.TotalAmount = _TotalAmount;
        _DetailObj.Profile.TotalDiscount = _TotalDiscount;
        setDetail(_DetailObj);
    }
    const _DeleteItem = (EventIndex,ItemIndex) =>{
        const _EventItem = [... Events];
        let _EventObj = [];

        _EventItem.find((EVal,EIndex)=>{
            if(EventIndex != EIndex){
                _EventObj.push(EVal);
                return;
            }
            let _Obj = EVal;
            let _ItemObj = [];
            EVal.Items.find((Val,Index)=>{
                if(ItemIndex != Index){
                    _ItemObj.push(Val);
                    return;
                }
            });
            _Obj.Items = _ItemObj;
            _EventObj.push(_Obj);
        });
        setEvents(_EventObj);
        _UpdateCal(_EventObj);

    }

    const _AddItem = (EventIndex,Event)=>{
        _QuotationItemRef.current?.Show(EventIndex,Event);
    }
    const _PackageChangeClick = (EventIndex,d) =>{
        if(_PackageItemSelectIndex === undefined || _PackageItemSelectIndex === null) return;
        let _IndexAR = String(_PackageItemSelectIndex).split("_");
        if(_IndexAR.length != 2) return;
        if(Number(_IndexAR[0]) != EventIndex ) return;
        const _EventItem = [...Events];
        let _EventItemAR = [];
        const _Index = Number(_IndexAR[1]);

        _EventItem.find((ev,ei)=>{
            if(ei != EventIndex) {
                _EventItemAR.push(ev);
                return;
            }
            let _SelectObj = undefined;
            ev.Items.find((iv,ii)=>{
                if(ii === _Index) _SelectObj = iv;
            });
            let _ItemAR = [];
            let _curIndex = 0;
            if(d=== 1)
                _curIndex = _Index+1;
            if(d=== 2)
                _curIndex = _Index-1;
            if(_curIndex < 0) _curIndex = 0;
            if(_curIndex >= ev.Items.length){
                _EventItemAR.push(ev);
                return;
            };
            let _Update = false;
            let _ItemN = [];

            ev.Items.find((v,i)=>{
            if(d=== 2 && _curIndex === i){
                _SelectObj.LineNumber = _ItemN.length+1;
                _ItemN.push(_SelectObj);
                _Update = true;
            }
            if(i != _Index){
                let _Obj = v;
                _Obj.LineNumber =  _ItemN.length+1;
                _ItemN.push(_Obj);
            } 
            if(d=== 1 && _curIndex === i){
                _SelectObj.LineNumber = _ItemN.length+1;
                _ItemN.push(_SelectObj);
                _Update = true;
            }
            });
            if(_Update === false) {
                _SelectObj.LineNumber = _ItemN.length+1;
                _ItemN.push(_SelectObj);
            }
            ev.Items = _ItemN;
            _EventItemAR.push(ev);
            setPackageItemSelectIndex(EventIndex+"_"+_curIndex)

        });
        setEvents(_EventItemAR);
    }
    const _PackageItemClick = (EventIndex,Index) =>{
        const _i = EventIndex+"_"+Index;
        if(_i === _PackageItemSelectIndex)
            setPackageItemSelectIndex(undefined);
        else
           setPackageItemSelectIndex(_i);
    }
    const _ProductInfo = (v)=>{
        _ProductView.current?.Show(v.RefType,v.RefID,v.Name);
    }
    const _Save = async()=>{
        let _reqObj = {
            QuotationID : Detail.QuotationID,
            QuotationType : getValues('QuotationType'),
            CustomerID : Detail.Customer.CustomerID,
            AccountType: Detail.Profile.AccountType,
            EventAddress : getValues('EventAddress'),
            EventDistrictID : getValues('EventDistrictID').SubdistrictId,
            EventZipCode : getValues('EventZipCode'),
            EventLocation : getValues('EventLocation'),
            ContactName : getValues('ContactName'),
            ContactPhoneMain: getValues('ContactPhoneMain'),
            ContactPhoneSpare : getValues('ContactPhoneSpare'),
            Invoice : (Detail.Profile.InvoiceStatus === 1 ? true : false),
            InvoiceName : getValues('InvoiceName'),
            InvoiceTaxID : getValues('InvoiceTaxID'),
            InvoiceBranch : getValues('InvoiceBranch'),
            InvoiceAddress : getValues('InvoiceAddress'),
            InoiceZipCode : getValues('InoiceZipCode'),
            QuotationRemarkID : _QuotationRemarkID,
            Remark : getValues('Remark'),
            Note : getValues('Note'),
            LineChatURL : getValues('LineChatUrl'),
            FacebookURL : getValues('FacebookUrl'),
            CalendarURL : getValues('CalendarUrl'),
            Events:[],
            Items:[],
            Promotions:[]
        }
        Events.map((v,i)=>{
            _reqObj.Events.push({
                QuotationEventId : v.QuotationEventId,
                EventDate : v.EventDate,
                StandbyTime : v.StandbyTime,
                EventStart : v.EventStart,
                EventEnd:v.EventEnd
            });
            if(v.Items === undefined || v.Items === null) return;
            v.Items.map((iv,ii)=>{
                let _obj  ={...iv};
                _obj.EventKey = null;
                _obj.QuotationEventId = v.QuotationEventId;
                _reqObj.Items.push(_obj);
            });
        });
        _PromoitionList.map((v,i)=>{
            _reqObj.Promotions.push(v.PromotionID);
        });

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            Swal.showLoading();
            API({method : 'post',url:'/Quotation/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                _Load(Detail.QuotationID);
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }
    const _EditEventOpen = (Index,Event)=>{
        const _Obj = {...Event};
        setEventIndex(Index);
        setEventEdit(_Obj);
        setEventDate(_Obj.EventDate);
        if(_Obj.EventStart != null){
            setEventStart(String(_Obj.EventStart).split('T')[1]);
        }else{
            setEventStart(undefined);
        }
        if(_Obj.EventEnd != null){
            setEventEnd(String(_Obj.EventEnd).split('T')[1]);
        }else{
            setEventEnd(undefined);
        }
        if(_Obj.StandbyTime != null){
            setEventStandbyTime(String(_Obj.StandbyTime).split('T')[1]);
        }else{
            setEventStandbyTime(undefined);
        }
        setEventModalState(true);
    }
    const _SaveEditEvent = () =>{
        let _Obj = _EventEdit;

        _Obj.EventDate = _EventDate;
        _Obj.StandbyTime =  (_EventStandbyTime != null ? DateFormatJSON(_EventDate,false)+'T'+_EventStandbyTime:null);
        _Obj.EventStart =  (_EventStart != null ? DateFormatJSON(_EventDate,false)+'T'+_EventStart:null);
        _Obj.EventEnd =  (_EventEnd != null ? DateFormatJSON(_EventDate,false)+'T'+_EventEnd:null)
        const _EventItem = [...Events];
        let _EventItemAR = [];
        _EventItem.find((ev,ei)=>{
            if(ei != _EventIndex) {
                _EventItemAR.push(ev);
                return;
            }
            _EventItemAR.push({..._Obj});
        });

        setEvents(_EventItemAR);
        setEventIndex(undefined);
        setEventEdit(undefined);
        setEventDate(undefined);
        setEventStandbyTime(undefined);
        setEventStart(undefined);
        setEventEnd(undefined);
        setEventModalState(false);
    }
    const _PromotionItem = ()=>{
        _PromotionItemRef.current?.NewItem();
    }
    const _PromotionNewItem = (v)=>{
        let _refAR = [... _PromoitionList];
        _refAR.push(v);

        setPromotionList(_refAR);
    }
    const _PromotionDelete = (index)=>{
        const _refAR = [... _PromoitionList];
        let _newAR = [];
        _refAR.find((v,i)=>{
            if(i != index) _newAR.push(v);
            
        });
        setPromotionList([... _newAR]);
    }
    
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">ใบเสนอราคา {(Detail != null?Detail.QuotationCode:"")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>

            <div className='modal-body' style={{'minHeight':'500px'}}>
                <div className='row'>
                    <div className='col-lg-10'>
                        <div className='btn-group btn-lg'>
                            <button type="button" className={(ViewMode === 1 ? 'btn btn-info text-white active':'btn btn-secondary text-white')} onClick={()=>{setViewMode(1)}}>ข้อมูลใบเสนอราคา</button>
                            <button type="button" className={(ViewMode === 2 ? 'btn btn-info text-white active':'btn btn-secondary text-white')} onClick={()=>{setViewMode(2)}}>รายการแพ็คเกจ / สินค้า</button>
                            <button type="button" className={(ViewMode === 3 ? 'btn btn-info text-white active':'btn btn-secondary text-white')} onClick={()=>{setViewMode(3)}}>รายละเอียดลูกค้า</button>
                            <button type="button" disabled={(Detail != null && Detail.Profile.InvoiceStatus === 1 ? false : true)} className={(ViewMode === 4 ? 'btn btn-info text-white active':'btn btn-secondary text-white')} onClick={()=>{setViewMode(4)}}>ข้อมูลใบกำกับภาษี</button>
                            <button type="button" className={(ViewMode === 5 ? 'btn btn-info text-white active':'btn btn-secondary text-white')} onClick={()=>{setViewMode(5)}}>พิมพ์ใบเสนอราคา</button>
                        </div>
                    </div>
                    <div className='col-lg-2 fs-5 text-end'>
                        {(Detail != null ? parse(QuotationStatusLabel(Detail.Profile.Status)):"")}
                    </div>
                </div>
                {(ViewMode === 1 ? <>
                    <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mt-3'>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">เลขที่ใบเสนอราคา</label>
                                        <div className='form-control'>{(Detail != null?Detail.QuotationCode:"")}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">วันที่เสนอราคา</label>
                                        <div className='form-control'>{(Detail != null?DateThaiFormat(Detail.Profile.QuoteDate,false):"")}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">ประเภท</label>
                                        <div className='form-control text-center'>{(Detail != null?AccountTypeName(Detail.Profile.AccountType):"")}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">ยอดรวม</label>
                                        <div className='form-control text-end'>{(Detail != null?NumberFormat(Detail.Profile.TotalAmount):"")}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">ยอดมัดจำ</label>
                                        <div className='form-control text-end'>{(Detail != null?NumberFormat(Math.ceil(Detail.Profile.QuoteAmount/2,0)):"")}</div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">รหัสลูกค้า</label>
                                        <div className='form-control'>{(Detail != null?Detail.Customer.CustomerCode:"")}</div>
                                    </div>
                                    <div className="col-md-5">
                                        <label className="form-label title-label">ชื่อผู้ติดต่อ</label>
                                        <input type='text' className="form-control" {...register("ContactName", { required: false })}  />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">เบอร์โทร</label>
                                        <input type='text' className="form-control" {...register("ContactPhoneMain", { required: false })}  />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">เบอร์โทร สำรอง</label>
                                        <input type='text' className="form-control"  {...register("ContactPhoneSpare", { required: false })} />
                                    </div>

                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-4">
                                        <label className="form-label title-label">ที่อยู่จัดงาน</label>
                                        <Controller name="EventAddress" control={control} rules={{required:false}} render={({field})=>(
                                            <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                                        )} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label title-label">ตำบล / อำเภอ / จังหวัด</label>
                                        <Controller name="EventDistrictID" control={control} rules={{required:false}} render={({field})=>(
                                        <AsyncSelect 
                                            name={field.name}
                                            value={field.value}
                                            cacheOptions 
                                            defaultOptions 
                                            placeholder={'ค้นหา ตำบล / อำเภอ / จังหวัด'} 
                                            loadOptions={(e)=>SearchSubDistrict(e)} 
                                            onChange={(e)=>field.onChange(e)}
                                            getOptionLabel={(e)=> e.FullName} 
                                            getOptionValue={(e)=>e.SubdistrictId} />
                                        )} />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">รหัสไปรษณีย์</label>
                                        <input type='text' className="form-control text-center" {...register("EventZipCode", { required: false })}/>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    <div className="col-md-4">
                                        <label className="form-label title-label">พิกัด GPS</label>
                                        <input type='text' className="form-control" {...register("EventLocation", { required: false })} />
                                    </div>
                                </div>
                                <div className='row mt-3 justify-content-center'>
                                    <div className='col-lg-10'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>วันที่จัดงาน</th>
                                                    <th className='text-center'>รอบ</th>
                                                    <th className='text-center'>เวลาถึงหน้างาน</th>
                                                    <th className='text-center'>เวลาเริ่มบริการ</th>
                                                    <th className='text-center'>เวลาสิ้นสุดบริการ</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {(Events != null?Events.map((v,i)=>(
                                                <tr key={i}>
                                                    <td className='text-center'>{DateThaiFormat(v.EventDate,false)}</td>
                                                    <td className='text-center'>{v.EventShift}</td>
                                                    <td className='text-center'>{TimeFormat(v.StandbyTime)}</td>
                                                    <td className='text-center'>{TimeFormat(v.EventStart)}</td>
                                                    <td className='text-center'>{TimeFormat(v.EventEnd)}</td>
                                                    <td className='text-center'>
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type="button" className='btn btn-warning text-white' onClick={()=>{_EditEventOpen(i,v);}}>แก้ไข</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )):"")}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-md-6'>
                                        <label className="form-label title-label">หมายเหตุ</label>
                                        <select className='form-control' value={_QuotationRemarkID} onChange={(e)=>{setQuotationRemarkID(e.target.value);setQuotationRemark(null);_QuotationRemarkList.find((v,i)=>{ if(v.QuotationRemarkId === Number(e.target.value)){setQuotationRemark(v);}})}}>
                                            <option value={0} className='text-center'>กรุณาเลือกหมายเหตุ</option>
                                            {_QuotationRemarkList.map((v,i)=>(
                                                <option value={v.QuotationRemarkId}>{v.QuotationRemarkName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-6'>
                                        <label className="form-label title-label">รายละเอียดหมายเหตุ</label>
                                        <textarea className='form-control' readOnly={true} value={(_QuotationRemark != null ? _QuotationRemark.Remark:'')}></textarea>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-6">
                                        <label className="form-label title-label">หมายเหตุ พิเศษ</label>
                                        <textarea className="form-control" {...register("Remark", { required: false })} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label title-label">Note</label>
                                        <textarea className="form-control" {...register("Note", { required: false })}/>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-4">
                                        <label className="form-label title-label">Facebook</label>
                                        <Controller name="FacebookUrl" control={control} rules={{required:false}} render={({field})=>(
                                            <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                                        )} />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title-label">Line Chat</label>
                                        <Controller name="LineChatUrl" control={control} rules={{required:false}} render={({field})=>(
                                            <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                                        )} />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title-label">Calendar</label>
                                        <Controller name="CalendarUrl" control={control} rules={{required:false}} render={({field})=>(
                                            <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                                        )} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </>:"")}
                {(ViewMode === 2 ? <>
                    <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mt-4'>
                                    <div className='col-md-12'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>ลำดับ</th>
                                                    <th className='text-center'>ประเภท</th>
                                                    <th className='text-center'>รายการ</th>
                                                    <th className='text-center'>รายละเอียด</th>
                                                    <th className='text-center' style={{'width':'100px'}}>จำนวน</th>
                                                    <th className='text-center' style={{'width':'120px'}}>ราคาต่อหน่วย</th>
                                                    <th className='text-center' style={{'width':'150px'}}>ราคารวม</th>
                                                    <th className='text-end'></th>
                                                </tr>
                                            </thead>
                                            {(Events ?Events.map((EventVal,EventIndex)=>(
                                                    <>
                                                    <tr key={EventIndex}>
                                                        <td className='bg-light bg-gradient text-center fw-bold fs-5' style={{'paddingTop':'15px'}} colSpan={3}>
                                                            {DateThaiFormat(EventVal.EventDate,false)}
                                                        </td>
                                                        <td className='bg-light  fs-6' style={{'paddingTop':'15px'}} colSpan={3}>
                                                            เวลาจัดงาน {TimeFormat(EventVal.EventStart)} - {TimeFormat(EventVal.EventEnd)}
                                                        </td>
                                                        <td className='bg-light text-end' colSpan={2}>
                                                            <div className='btn-group btn-group-sm'>
                                                                <button className='btn btn-success' onClick={()=>{_AddItem(EventIndex,EventVal);}}>เพิ่ม</button>
                                                                <button type="button" className='btn btn-primary' onClick={()=>{_PackageChangeClick(EventIndex,1)}} disabled={(_PackageItemSelectIndex === undefined || _PackageItemSelectIndex === null ? true:false)}>+</button>
                                                                <button type="button" className='btn btn-primary' onClick={()=>{_PackageChangeClick(EventIndex,2);}} disabled={(_PackageItemSelectIndex === undefined || _PackageItemSelectIndex === null ? true:false)}>-</button>
                                                            </div>
                                                        </td> 
                                                    </tr>
                                                    {(EventVal.Items ? EventVal.Items.map((v,i)=>(
                                                        <tbody key={i}>
                                                            <tr onClick={()=>{_PackageItemClick(EventIndex,i);}} className={(_PackageItemSelectIndex === EventIndex+'_'+i?'table-active':'')}>
                                                                <td className='text-center'>{v.LineNumber}</td>
                                                                <td className='text-left'>{v.GroupName}</td>
                                                                <td className='text-left'>{v.Name}</td>
                                                                <td className='text-left'>
                                                                    <textarea className='form-control' value={v.Description} onChange={(e)=>{
                                                                        v.Description = e.target.value;
                                                                        _UpdateItem(EventIndex,i,v);
                                                                    }}></textarea>
                                                                </td>
                                                                <td className='text-center'>
                                                                    <input type="number" min={0} className='form-control text-center' value={v.Quantity} onChange={(e)=>{
                                                                        v.Quantity = e.target.value;
                                                                        v.TotalAmount = v.Quantity * v.UnitPrice;
                                                                        _UpdateItem(EventIndex,i,v);
                                                                    }}/>
                                                                </td>
                                                                <td className='text-end'>
                                                                    <input type="number" min={0} className='form-control text-end' value={v.UnitPrice} onChange={(e)=>{
                                                                        v.UnitPrice = e.target.value;
                                                                        v.TotalAmount = v.Quantity * v.UnitPrice;
                                                                        _UpdateItem(EventIndex,i,v);
                                                                    }}/>
                                                                </td>
                                                                <td className='text-end'>{v.FOC?'-'+NumberFormat(v.DiscountAmount):NumberFormat(v.TotalAmount)}</td>
                                                                <td className='text-end'>
                                                                    <div className='btn-group btn-group-sm'>
                                                                        <button type="button" className={(v.FOC?'btn btn-success':'btn btn-secondary')} onClick={()=>{
                                                                            if(v.FOC) 
                                                                            {
                                                                                v.FOC = false;
                                                                                v.TotalAmount = v.Quantity * v.UnitPrice;
                                                                                v.DiscountAmount = 0;
                                                                            }else{
                                                                                v.FOC=true;
                                                                                v.DiscountAmount = v.Quantity * v.UnitPrice;
                                                                                v.TotalAmount =0;
                                                                            }
                                                                            _UpdateItem(EventIndex,i,v);
                                                                        }}>ของแถม</button>
                                                                        <button className='btn btn-danger' onClick={()=>{_DeleteItem(EventIndex,i);}}>ลบ</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr onClick={()=>{_PackageItemClick(EventIndex,i);}} className={(_PackageItemSelectIndex === EventIndex+'_'+i?'table-active':'')}>
                                                                <td></td>
                                                                <td colSpan={6}>
                                                                    <textarea className='form-control' readOnly={true} value={(v.Remark != null ? v.Remark:'')}></textarea>
                                                                </td>
                                                                <td className='text-end'>
                                                                    <div className='btn-group btn-group-sm'>
                                                                        <button type='button' className='btn btn-info text-white' onClick={()=>{_ProductInfo(v);}}>รายละเอียด</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )):"")}
                                                    </>
                                                    
                                                    
                                                )):"")}
                                        </table>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-8'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>รหัสโปรโมชั่น</th>
                                                    <th className='text-center'>โปรโมชั่น</th>
                                                    <th className='text-center'>รายละเอียด</th>
                                                    <th className='text-center'>ส่วนลด</th>
                                                    <th className='text-end'>
                                                    <button type="button" className='btn btn-success btn-sm'  onClick={()=>{_PromotionItem();}}>+ เพิ่มรายการ</button>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(_PromoitionList.map((v,i)=>(
                                                    <tr key={i}>
                                                        <td className='text-center'>{v.PromotionCode}</td>
                                                        <td className='text-center'>{v.PromotionName}</td>
                                                        <td className='text-center'>{v.Description}</td>
                                                        <td className='text-center'>{v.DiscountValue}</td>
                                                        <td className='text-center'>
                                                            <div className='btn-group btn-group-sm'>
                                                                <button type='button' className='btn btn-danger' onClick={(e)=>{_PromotionDelete(i);}}>ลบ</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-4'>
                                        <div className='row mt-3 justify-content-end'>
                                            <div className='col-lg-7 text-end'><h4>รวมเป็นเงิน</h4></div>
                                            <div className='col-lg-5 text-end'>
                                                <h4 className='fs-4 text-danger'>{NumberFormat(Detail.Profile.TotalAmount)}</h4>
                                            </div>
                                        </div>
                                        <div className='row mt-1 justify-content-end'>
                                            <div className='col-lg-7 text-end'><h4>ส่วนลด</h4></div>
                                            <div className='col-lg-5 text-end'>
                                                <h4 className='fs-4 text-danger'>{NumberFormat(Detail.Profile.TotalDiscount)}</h4>
                                            </div>
                                        </div>
                                        {(Detail != null & Detail.Profile.AccountType === 1 ?
                                            <>
                                            <div className='row mt-1 justify-content-end'>
                                                <div className='col-lg-7 text-end'><h4>จำนวนภาษีมูลค่าเพิ่ม 7%</h4></div>
                                                <div className='col-lg-5 text-end'>
                                                    <h4 className='fs-4 text-danger'>{NumberFormat(((Detail.Profile.TotalAmount*7)/100))}</h4>
                                                </div>
                                            </div>
                                            <div className='row mt-1 justify-content-end'>
                                                <div className='col-lg-7 text-end'><h4>จำนวนเงินทั้งสิ้น</h4></div>
                                                <div className='col-lg-5 text-end'>
                                                    <h4 className='fs-4 text-success'>{NumberFormat(((Detail.Profile.TotalAmount*7)/100)+Detail.Profile.TotalAmount)}</h4>
                                                </div>
                                            </div>
                                            </>
                                        :
                                            <>

                                            <div className='row mt-1 justify-content-end'>
                                                <div className='col-lg-7 text-end'><h4>จำนวนเงินทั้งสิ้น</h4></div>
                                                <div className='col-lg-5 text-end'>
                                                    <h4 className='fs-4 text-success'>{NumberFormat(Detail.Profile.TotalAmount)}</h4>
                                                </div>
                                            </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                

                                
                            </div>
                        </div>

                    </div>
                </div>
                </>:"")}
                {(ViewMode === 3 ? <>
                    <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mt-3'>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">รหัสลูกค้า</label>
                                        <div className='form-control'>{(Detail != null?Detail.Customer.CustomerCode:"")}</div>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title-label">ชื่อลูกค้า</label>
                                        <div className='form-control'>{(Detail != null?Detail.Customer.Name:"")}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">เบอร์โทร</label>
                                        <div className='form-control'>{(Detail != null?Detail.Customer.Mobile:"")}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">ชื่อเล่น</label>
                                        <div className='form-control'>{(Detail != null?Detail.Customer.NickName:"")}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">วันเกิดลูกค้า</label>
                                        <div className='form-control  text-center'>{(Detail != null?DateThaiFormat(Detail.Customer.BrithDay,false):"")}</div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">ประเภท</label>
                                        <div className='form-control text-center'>{(Detail != null?Detail.Customer.AccountTypeName:"")}</div>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label title-label">กลุ่มลูกค้า</label>
                                        <div className='form-control text-center'>{(Detail != null && Detail.Customer.CustomerGroup != null?Detail.Customer.CustomerGroup.CustomerGroupName:"")}</div>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label title-label">ประเภทลูกค้า</label>
                                        <div className='form-control text-center' >{(Detail != null && Detail.Customer.CustomerType != null?Detail.Customer.CustomerType.CustomerTypeName:"-")}</div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-4">
                                        <label className="form-label title-label">ที่อยู่</label>
                                        <div className='form-control'>{(Detail != null?Detail.Customer.Address:"")}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label title-label">ตำบล / อำเภอ / จังหวัด</label>
                                        <div className='form-control'>{(Detail != null?Detail.Customer.Subdistrict.FullName:"")}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label title-label">รหัสไปรษณีย์</label>
                                        <div className='form-control text-center' style={{minHeight:'38px'}}>{(Detail != null?Detail.Customer.ZipCode:"-")}</div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </>:"")}
                {(ViewMode === 4 ? <>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row mt-3'>
                                        <div className="col-md-6">
                                            <label className="form-label title-label">ชื่อธุรกิจ</label>
                                            <input type='text' className="form-control" {...register("InvoiceName", { required: false })} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label title-label">เลขประจำตัวผู้เสียภาษี</label>
                                            <input type='text' className="form-control" {...register("InvoiceTaxID", { required: false })} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label title-label">สำนักงาน/สาขา</label>
                                            <input type='text' className="form-control" {...register("InvoiceBranch", { required: false })} />
                                        </div>

                                    </div>
                                    <div className='row mt-3'>
                                        <div className="col-md-8">
                                            <label className="form-label title-label">ที่อยู่</label>
                                            <Controller name="InvoiceAddress" control={control} rules={{required:false}} render={({field})=>(
                                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                                            )} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label title-label">รหัสไปรษณีย์</label>
                                            <input type='text' className="form-control" {...register("InoiceZipCode", { required: false })} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>:"")}
                {(ViewMode === 5 ? <>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-6 text-center'>
                                            <button className='btn btn-info text-white btn-lg' type='button' onClick={()=>{_PDF();}}>PDF</button>
                                        </div>
                                        <div className='col-lg-6 text-center'>
                                            <button className='btn btn-warning text-white btn-lg' type='button' onClick={()=>{_Image();}}>Image</button>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-12 '>
                                        {_PDFImageList.map((v,i)=>(
                                            <div className='row justify-content-center' key={i}>
                                                <div className='col-10'>
                                                    <img className='form-control' src={'data:image/jpeg;base64,'+v}/>
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </>:"")}
                <div className='row mt-3 justify-content-end'>
                    <div className='col-lg-2 text-end'>
                        <button type='button' className='btn btn-success btn-lg' onClick={(()=>{_Save();})}>บันทึก</button>
                    </div>
                </div>
            </div>
        </Modal>
        <QuotationItemNewCard ref={_QuotationItemRef} update={(EventIndex,Items)=>{
            const _EventItem = [... Events];
            let _EventObj = [];

            _EventItem.find((EVal,EIndex)=>{
                if(EventIndex != EIndex){
                    _EventObj.push(EVal);
                    return;
                }
                let _Obj = EVal;
                _Obj.Items = Items;
                _EventObj.push(_Obj);
            });
            setEvents(_EventObj);
            _UpdateCal(_EventObj);
        }}/>
        <ProductView ref={_ProductView}/>
        <Modal isOpen={EventModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">แก้ไข {(_EventEdit != undefined ? DateThaiFormat(_EventEdit.EventDate):"")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setEventModalState(false)}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'200px'}}>
                <div className='row justify-content-center'>
                    <div className="col-md-2">
                        <label className="form-label title-label">วันที่จัดงาน</label>
                        <ThaiDatePicker minDate={moment().add(1,'days').toDate()} inputProps={{displayFormat: "DD/MM/YYYY",className:'text-center form-control' }} value={_EventDate} onChange={(c,b)=>{setEventDate(c);}}/>
                    </div>
                    <div className="col-md-2">
                        <label className="form-label title-label">เวลาถึงหน้างาน</label>
                        <TimePicker className='form-control text-center' onChange={(e)=>{setEventStandbyTime(e);}} locale='sv-sv' disableClock={true} value={_EventStandbyTime}/>
                    </div>
                    <div className="col-md-2">
                        <label className="form-label title-label">เวลาเริ่มบริการ</label>
                        <TimePicker className='form-control text-center' onChange={(e)=>{setEventStart(e);}} locale='sv-sv' disableClock={true} value={_EventStart}/>
                    </div>
                    <div className="col-md-2">
                        <label className="form-label title-label">เวลาสิ้นสุดบริการ</label>
                        <TimePicker className='form-control text-center' onChange={(e)=>{setEventEnd(e);}} locale='sv-sv' disableClock={true} value={_EventEnd}/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-lg-12 text-end'>
                        <button type='button' className='btn btn-success btn-lg' onClick={()=>{_SaveEditEvent();}}>บันทึก</button>
                    </div>
                </div>
            </div>
        </Modal>

        <PromotionItem ref={_PromotionItemRef} newitem={(v)=>{_PromotionNewItem(v)}}/>
        </>)
        
});

export default React.memo(QuotationInfoCard)